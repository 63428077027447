input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px var(--third-color) inset; /* Changez la couleur de fond ici */
    box-shadow: 0 0 0px 1000px var(--third-color) inset; /* Changez la couleur de fond ici */
    -webkit-text-fill-color: var(--fourth-color); /* Couleur du texte */
    transition: background-color 5000s ease-in-out 0s;
}

/* Style du conteneur de l'alerte */
.closed-alert {
    background-color: var(--primary-color);  /* Fond rouge très clair */
    border: 1px solid #ffcdd2;  /* Bordure rouge claire */
    border-radius: 4px;
    padding: 15px;
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

/* Style de l'icône d'avertissement */
.closed-alert .material-symbols-outlined {
    color: #d32f2f;  /* Rouge plus foncé pour l'icône */
}

/* Style du message */
.closed-message {
    color: #d32f2f;  /* Rouge plus foncé pour le texte */
    margin: 0;
    line-height: 1.5;  /* Espacement des lignes pour une meilleure lisibilité */
}

/* Style du bouton désactivé */
input[type="submit"].disabled-button {
    background-color: #cccccc;  /* Gris pour indiquer que le bouton est désactivé */
    cursor: not-allowed;  /* Curseur indiquant que l'action n'est pas possible */
    opacity: 0.7;  /* Légère transparence */
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    backdrop-filter: blur(5px);
}

.loader-container {
    background: white;
    padding: 30px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid var(--primary-color);
    border-bottom-color: var(--third-color);
    border-radius: 50%;
    display: inline-block;
    animation: rotation 1s linear infinite;
    margin-bottom: 15px;
}

.loader-container p {
    color: var(--third-color);
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    margin: 0;
}

@keyframes rotation {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.delivery-mode-info{
    color: var(--fourth-color);
}

.name-item-basket{
    color: var(--fourth-color)!important;
}

.textRestaurantClose{
    color: var(--fourth-color);
}

.error-message{
    color: var(--fourth-color);
    font-family: var(--primary-font);
    font-size: 12px;
    text-align: left!important;
}

.comment-input{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--secondary-color);
    border: 2px solid var(--fourth-color);
    min-width: 250px;
    max-width: 455px;
    width: calc(100% - 40px);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
}

.button-comment{
    display: block;
    margin: 0 auto;
    width: 200px;
    height: 35px;
    margin-top: 10px;
    background-color: var(--third-color);
    border: 1px solid var(--primary-color);
    color: var(--fourth-color);
    box-sizing: border-box;
    transition: background-color .3s;
    border-radius: 10px;

    &:hover{
        cursor: pointer;
        background-color: var(--primary-color);  
        color: var(--fourth-color);
    }
}

.containerBasketItem{
    border-bottom: 1px solid var(--primary-color-opacity);
    color: var(--primary-color);
    padding-bottom: 20px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
}

.containerBasketItem p{
    margin: 0;
}

.comment-input h3{

    color: var(--fourth-color);
    font-family: var(--secondary-font);
    text-align: center;
}

.comment-input textarea{
    background-color: var(--third-color);
    padding-top: 10px;
    height: 100px;
    resize: vertical;
    margin-bottom: 20px;
    border-radius: 10px;
}

.closed-message{
    color: #fff;
}

.container-btn-item span{
    color: var(--primary-color);
    font-size: 28px;

    &:hover{
        cursor: pointer;
    }
}

.container-btn-item{
    align-items: center;
}

.container-input-address{
    display: flex;
    width: 100%;
    gap: 20px;
}

.containerGlobalBasket {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 140px 100px;
    gap: 20px;
    flex-wrap: wrap;
}

.containerItemsBasket, .containerFormBasket {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
}

.containerItemsBasket {
    border: 2px solid var(--primary-color);
}

.containerItemsBasket h2 {
    color: var(--fourth-color);
    text-align: center;
    font-size: 2rem;
    font-family: var(--primary-font);
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 20px!important;
}

.textGold {
    color: var(--primary-color);
    font-weight: bold;
}

.basket-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.basket-item p {
    color: #fff;
    font-family: var(--seondary-font);
    font-size: 1rem;
}

.container-btn-item {
    display: flex;
    gap: 20px;
}

.container-btn-item button {
    background-color: var(--primary-color);
    color: #fff;
    height: 40px;
    width: 40px;
    border: 2px solid var(--primary-color);
    font-size: 1.5rem;
    font-weight: bold;
    transition: all .3s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-btn-item button:hover {
    cursor: pointer;
    background-color: var(--primary-color);
}

.total-price {
    color: var(--fourth-color);
    font-family: var(--seondary-font);
    font-size: 1rem;
    text-align: end;
}

.basket-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--fourth-color);
    font-family: var(--seondary-font);
}

.basket-empty span {
    font-size: 4rem;
    margin-bottom: 30px;
    margin-top: 30px;
}

.basket-empty p {
    margin-bottom: 30px;
}

.containerFormBasket {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.containerFormBasket form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.containerFormBasket form input, select, textarea {
    width: 100%;
    height: 45px;
    margin-top: 10px;
    background-color: var(--third-color);
    border: 1px solid var(--primary-color);
    color: var(--fourth-color);
    box-sizing: border-box;
    transition: all .3s;
    padding-left: 20px;
    border-radius: 10px;
}

.container-input-delivery{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-bottom: 20px;
}

.containerFormBasket textarea{
    padding-top: 10px;
    height: 100px;
    resize: vertical;
}


input[type=submit]:hover {
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--third-color);
}
input[type=submit] {
    padding: 0!important;
}

select:hover {
    cursor: pointer;
}

/* Media queries for responsiveness */
@media (min-width: 768px) {
    .containerGlobalBasket {
        gap: 30px;
    }

}

@media (max-width: 890px) {
    .containerGlobalBasket {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}

@media (min-width: 1024px) {
    .containerGlobalBasket {
        gap: 50px;
    }
    .containerItemsBasket {
        padding-left: 50px;
        padding-right: 50px;
    }
    .containerItemsBasket h2 {
        font-size: 3.8rem;
    }
    .total-price {
        font-size: 1.2rem;
    }

}



@media (max-width: 550px) {
    .containerFormBasket{
        width: 100%;
    }
}