.toast {
  position: fixed;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--primary-color);
  color: white;
  padding: 16px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  transition: all 0.3s ease;
  opacity: 0;
}

.toast.show {
  top: 24px;
  opacity: 1;
}

.toast p {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

.toast .material-symbols-outlined {
  color: white;
  font-size: 24px;
}

.containerGlobalMenu {
  color: var(--primary-color);
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
}

.lineMenu {
  border: 2px solid var(--primary-color);
  margin-top: 140px;
}

.titleMenu {
  font-size: 3.8em;
  margin-bottom: 20px;
  text-align: center;
  font-family: var(--primary-font);
}

.filter select {
  width: 200px;
  height: 45px;
  text-align: center;
  text-align-last: center;
  font-size: 1em;
  color: #fff;
  background-color: var(--secondary-color);
  border: 1px solid var(--primary-color);
  font-family: var(--secondary-font);
  border-radius: 5px;
  appearance: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  padding: 0;
}

.filter select:hover {
  background-color: var(--primary-color);
}

.filter select:focus {
  background-color: var(--secondary-color);
}

.filter {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
  gap: 20px;
}

/* Version sans image */
.menu-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.1em;
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
  border-left: 2px solid var(--primary-color);
  padding-left: 20px;
  position: relative;
  font-family: var(--secondary-font);
}

/* Version avec image */
.menu-with-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 2rem;
}

.menu-item.with-image {
  border-left: none;
  padding-left: 0;
  background-color: var(--secondary-color);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.menu-item.with-image:hover {
  transform: translateY(-5px);
}

.item-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-bottom: 2px solid var(--primary-color);
}

.item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.menu-item.with-image:hover .item-image {
  transform: scale(1.05);
}

.item-content {
  padding: 1.5rem;
}

.titlePriceProduct {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.item-description {
  font-size: 0.8em;
  color: #fff;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.item-description::after {
  content: attr(data-full-description);
  position: absolute;
  left: 0;
  bottom: 125%;
  width: max-content;
  max-width: 300px;
  background-color: var(--secondary-color);
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  white-space: normal;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
  z-index: 10;
}

.item-description:hover::after {
  opacity: 1;
  visibility: visible;
}

.categoryTitle {
  font-size: 1.55rem;
  color: #fff;
  margin-top: 42px;
  margin-bottom: 52px;
  text-align: center;
  font-family: var(--secondary-font);
}

.addLocalStorage {
  background-color: var(--secondary-color);
  border: 2px solid var(--primary-color);
  color: #fff;
  height: 40px;
  margin-top: 20px;
  transition: all .3s;
  width: 100%;
  border-radius: 4px;
}

.addLocalStorage:hover {
  background-color: var(--primary-color);
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 10px;
}

.pagination button {
  background-color: var(--secondary-color);
  border: 1px solid var(--primary-color);
  color: #fff;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.pagination button:hover,
.pagination button.active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  .titleMenu {
    font-size: 2em;
  }

  .lineMenu {
    margin-top: 100px;
  }

  .menu-item,
  .menu-item.with-image {
    width: 100%;
    max-width: none;
  }

  .filter select {
    width: 100%;
  }

  .menu-with-images {
    grid-template-columns: 1fr;
  }

  .item-image-container {
    height: 180px;
  }
}

@media (max-width: 1090px) {
  .titleMenu {
    font-size: 2.6em;
  }

  .menu-item,
  .menu-item.with-image {
    width: 90%;
    max-width: none;
  }

  .filter select {
    width: 50%;
  }

  .categoryTitle {
    font-size: 1.5em;
  }
}

@media (max-width: 450px) {
  .titleMenu {
    font-size: 2em;
  }

  .categoryTitle {
    font-size: 1.3em;
  }
}