.containerGlobalMap {
    margin-top: 140px;
    width: 100%;
    position: relative;
    height: 0;
    padding-bottom: 450px;
}

.containerGlobalMap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
}

/* Responsive Styles */
@media (max-width: 8580px) {
    .containerGlobalMap {
        padding-bottom: 250px; /* 4:3 Aspect Ratio */
    }

    .containerGlobalMap iframe {
        height: 300px;
    }
}
